import React from "react";
import { motion } from "framer-motion";

const CookiesPolicy = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto p-8 max-w-4xl bg-white rounded-lg shadow-lg"
    >
      <motion.h2
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
        className="text-3xl md:text-4xl font-bold text-center mt-6 mb-8 text-black"
      >
        Cookies Policy
      </motion.h2>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        <p className="mt-4 text-base md:text-lg text-gray-700 leading-relaxed">
          At Al Professor Center, we value your privacy and are committed to protecting your personal data. As part of this commitment, we want to inform you that we do not collect or store any cookies from our users. We believe in providing a safe and transparent browsing experience, free from unnecessary tracking or data collection.
        </p>
        <p className="mt-4 text-base md:text-lg text-gray-700 leading-relaxed">
          Our website is designed to respect your privacy, and we ensure that your online experience with us remains secure and private. You can browse our site with confidence, knowing that your information is not being tracked or stored in any way.
        </p>
        <p className="mt-4 text-base md:text-lg text-gray-700 leading-relaxed">
          If you have any questions or concerns about our cookies policy, please feel free to contact us. We are here to address any inquiries you may have and to provide further clarification on our privacy practices.
        </p>
      </motion.div>

      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ delay: 1, duration: 0.5 }}
        className="mt-8 text-center"
      >
        <a
          href="/contact"
          className="inline-block bg-primary-blue text-white px-6 py-3 rounded-lg shadow-md hover:bg-primary-dark"
        >
          Contact Us for More Information
        </a>
      </motion.div>
    </motion.div>
  );
};

export default CookiesPolicy;
