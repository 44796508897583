import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy, limit, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { FaRegClock, FaUser, FaEdit, FaTrashAlt } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext'; 
import { useTranslation } from 'react-i18next';
import heroBg from '../../assets/hero-bg.png';

const BlogList = () => {
    const [posts, setPosts] = useState([]);
    const { userRole } = useAuth();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir() === "rtl";

    useEffect(() => {
        const fetchPosts = async () => {
            const q = query(collection(db, 'posts'), orderBy('postDate', 'desc'), limit(10));
            const querySnapshot = await getDocs(q);
            const postsData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setPosts(postsData);
        };

        fetchPosts();
    }, []);

    const handleDelete = async (postId) => {
        try {
            await deleteDoc(doc(db, 'posts', postId));
            setPosts(posts.filter(post => post.id !== postId));
        } catch (error) {
            console.error(t('errorDeletingPost'), error);
        }
    };

    return (
        <div className={`relative my-10 pt-20 bg-no-repeat bg-cover ${isRTL ? 'text-right' : 'text-left'}`} style={{ backgroundImage: `url(${heroBg})` }}>
            <div className="p-6 max-w-7xl mx-auto">
                <h1 className="text-3xl font-bold mb-6 text-gray-800">{t('latestBlogs')}</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {posts.map((post) => (
                        <div key={post.id} className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105">
                            <Link to={`/blog/${post.id}`} className="block h-48 bg-cover bg-center" style={{ backgroundImage: `url(${post.coverImage})` }}>
                                <img src={post.coverImage} alt={post.title} className="w-full h-full object-cover opacity-0" />
                            </Link>
                            <div className="p-4">
                                <Link to={`/blog/${post.id}`}>
                                    <h2 className="text-2xl font-semibold text-gray-800 hover:text-blue-600 transition-colors">{post.title}</h2>
                                </Link>
                                <p className="text-gray-600 mt-2 line-clamp-3">{post.description}</p>
                                <div className="flex items-center justify-between mt-4">
                                    <div className="flex items-center text-gray-500 text-sm">
                                        <FaUser className="mr-2" />
                                        <span>{post.author}</span>
                                    </div>
                                    <div className="flex items-center text-gray-500 text-sm">
                                        <FaRegClock className="mr-2" />
                                        <span>{new Date(post.postDate.toDate()).toLocaleDateString()}</span>
                                    </div>
                                </div>
                                {userRole === 'admin' && (
                                    <div className={`flex justify-${isRTL ? 'start' : 'end'} mt-4 gap-4`}>
                                        <button
                                            onClick={() => navigate(`/edit-post/${post.id}`)}
                                            className="text-blue-600 hover:text-blue-800"
                                        >
                                            <FaEdit />
                                        </button>
                                        <button
                                            onClick={() => handleDelete(post.id)}
                                            className="text-red-600 hover:text-red-800"
                                        >
                                            <FaTrashAlt />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BlogList;
