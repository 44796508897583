import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import inspectionImg from '../../assets/car-inspected.png';

const TradingTools = () => {
    const { t } = useTranslation();

    return (
        <div className="container mx-auto p-6 pt-24">
            <Helmet>
                <title>{t('tradingTools.pageTitle')}</title>
                <meta name="description" content={t('tradingTools.metaDescription')} />
                <meta name="keywords" content={t('tradingTools.metaKeywords')} />
                <link rel="canonical" href={window.location.href} />
                <meta name="robots" content="index, follow" />
                <html lang={t('lang')} dir={t('dir')} />
            </Helmet>

            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="text-center"
            >
                <h1 className="text-4xl font-bold text-gray-800 mb-6">{t('tradingTools.heading')}</h1>
                <p className="text-lg text-gray-600 mb-6">
                    {t('tradingTools.intro')}
                </p>
                <img src={inspectionImg} alt={t('tradingTools.imageAlt')} className="w-full h-auto max-w-lg mx-auto rounded-lg shadow-md" />
            </motion.div>

            <div className="mt-12 bg-gray-100 p-6 rounded-lg">
                <h2 className="text-2xl font-semibold text-gray-800">{t('tradingTools.servicesTitle')}</h2>
                <p className="text-lg text-gray-700 mt-4">
                    {t('tradingTools.servicesIntro')}
                </p>
                <ul className="list-disc list-inside text-lg text-gray-700 mt-4 space-y-2">
                    <li>{t('tradingTools.service1')}</li>
                    <li>{t('tradingTools.service2')}</li>
                    <li>{t('tradingTools.service3')}</li>
                    <li>{t('tradingTools.service4')}</li>
                    <li>{t('tradingTools.service5')}</li>
                </ul>
            </div>

            <div className="mt-8 bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-2xl font-semibold text-gray-800">{t('tradingTools.benefitsTitle')}</h3>
                <p className="text-lg text-gray-700 mt-4">
                    {t('tradingTools.benefitsIntro')}
                </p>
                <ul className="list-disc list-inside text-lg text-gray-700 mt-4 space-y-2">
                    <li>{t('tradingTools.benefit1')}</li>
                    <li>{t('tradingTools.benefit2')}</li>
                    <li>{t('tradingTools.benefit3')}</li>
                </ul>
            </div>

            <div className="mt-8 bg-gray-100 p-6 rounded-lg">
                <h3 className="text-2xl font-semibold text-gray-800">{t('tradingTools.whyUsTitle')}</h3>
                <p className="text-lg text-gray-700 mt-4">
                    {t('tradingTools.whyUsIntro')}
                </p>
                <ul className="list-disc list-inside text-lg text-gray-700 mt-4 space-y-2">
                    <li>{t('tradingTools.whyUs1')}</li>
                    <li>{t('tradingTools.whyUs2')}</li>
                    <li>{t('tradingTools.whyUs3')}</li>
                </ul>
            </div>

            <div className="mt-8 text-center">
                <a href="/book" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    {t('tradingTools.cta')}
                </a>
            </div>
            <div className="mt-8 text-center">
                <a href="/contact" className="bg-cyan-500 hover:bg-cyan-700 text-white font-bold py-2 px-4 rounded">
                    {t('tradingTools.contactUs')}
                </a>
            </div>
        </div>
    );
};

export default TradingTools;
