import React from "react";
import { MdCarRepair } from "react-icons/md";
import { motion } from "framer-motion";
import PreRepairImg from "../../assets/pre-repair.png"

const PreRepairDiagnostics = () => {
    return (
        <div className="container mx-auto p-6 pt-24">
            <div className="flex flex-col lg:flex-row gap-8 items-center">
                <div className="w-full lg:w-1/2">
                    <motion.div
                        initial={{ opacity: 0, x: -100 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                        className="bg-white shadow-lg rounded-lg p-6"
                    >
                        <div className="flex items-center mb-4">
                            <MdCarRepair className="text-blue-500 w-16 h-16 mr-4" />
                            <h1 className="text-3xl font-bold text-gray-800">
                                Pre-repair Diagnostics
                            </h1>
                        </div>
                        <p className="text-lg text-gray-600 mt-4">
                            At <strong>Al Professor Technical Inspection</strong> in <strong>Abu Dhabi</strong>, our pre-repair diagnostics service accurately identifies and assesses vehicle issues before repairs are carried out. This ensures that repairs are needed and will be effective, saving you time and money.
                        </p>
                    </motion.div>
                </div>
                <div className="w-full lg:w-1/2">
                    <motion.div
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        <img
                            src={PreRepairImg}
                            alt="Pre-repair Diagnostics"
                            style={{ width: '320px', height: '416px' }}
                            className="rounded-lg shadow-md"
                        />
                    </motion.div>
                </div>
            </div>

            <div className="mt-8 bg-gray-100 p-6 rounded-lg">
                <h2 className="text-2xl font-semibold text-gray-800">What We Cover:</h2>
                <ul className="list-disc list-inside text-lg text-gray-700 mt-4 space-y-2">
                    <li>Comprehensive diagnostic scans</li>
                    <li>Engine and transmission analysis</li>
                    <li>Brake and suspension system evaluation</li>
                    <li>Detailed performance reports</li>
                </ul>
            </div>
        </div>
    );
};

export default PreRepairDiagnostics;
