import React from "react";
import { motion } from "framer-motion";
import { FaStar } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const Reviews = () => {
  const { t, i18n } = useTranslation();
  
  const isRTL = i18n.language === 'ar';

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className={`container mx-auto p-8 max-w-4xl bg-white rounded-lg shadow-lg ${isRTL ? 'text-right' : 'text-left'}`}
      dir={isRTL ? 'rtl' : 'ltr'}
    >
      <motion.h2
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
        className="text-3xl md:text-4xl font-bold text-center mt-6 mb-8 text-black"
      >
        {t('ourFiveStarReviews')}
      </motion.h2>
      
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        className="text-center"
      >
        <img src="https://hosting.renderforestsites.com/22517494/1084443/media/79c4eb41550828633925798e08c9633a.png" alt={t('companyLogo')} className="mx-auto w-32 h-32 mb-4" />
        <div className={`flex justify-center ${isRTL ? 'space-x-reverse' : 'space-x-2'} mb-6`}>
          {[...Array(5)].map((_, index) => (
            <FaStar key={index} className="w-6 h-6 text-yellow-500" />
          ))}
        </div>
        <p className="mt-4 text-base md:text-lg text-gray-700 leading-relaxed">
          {t('fiveStarDescription')}
        </p>
      </motion.div>
    </motion.div>
  );
};

export default Reviews;
