// firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, getDocs, getDoc, query, where, doc, setDoc, writeBatch, updateDoc, addDoc, deleteDoc, orderBy, limit, startAfter } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDTvFT01j9QxXh0TWPuWQdiFSwHhP3vHuI",
    authDomain: "al-professor-c7fa9.firebaseapp.com",
    projectId: "al-professor-c7fa9",
    storageBucket: "al-professor-c7fa9.appspot.com",
    messagingSenderId: "693737166085",
    appId: "1:693737166085:web:17c54ffac4fadc667ed36c",
    measurementId: "G-05PHPJZG2Y"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage();

export { app, analytics, auth, db, collection, getDocs, query, where, doc, setDoc, getDoc, writeBatch, updateDoc, addDoc, storage, deleteDoc, orderBy, limit, startAfter };
