import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Mail, Phone, MapPin, Send } from "lucide-react";
import Alert from "../ui/Alert";

const TextGenerateEffect = ({ words, className }) => {
  return (
    <motion.h2
      className={className}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {words}
    </motion.h2>
  );
};

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setTimeout(() => setIsSubmitted(false), 5000);
  };

  const contactInfo = [
    { icon: Phone, text: "+971 56 186 8236" },
    { icon: Mail, text: "contact@alprofessor.com" },
    { icon: MapPin, text: "Abu Dhabi, UAE" },
  ];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto p-8 max-w-4xl bg-white rounded-lg shadow-lg"
    >
      <TextGenerateEffect
        words="Get in Touch"
        className="text-4xl font-bold text-center text-black mt-6 mb-8"
      />

      <div className="grid md:grid-cols-2 gap-8">
        <div className="space-y-6">
          {contactInfo.map((item, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="flex items-center space-x-3 bg-gray-100 p-4 rounded-lg shadow-md"
            >
              <item.icon className="w-6 h-6 text-primary-blue" />
              <span className="text-black">{item.text}</span>
            </motion.div>
          ))}
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <AnimatePresence>
            {isSubmitted && (
              <Alert
                variant="success"
                message="Your message has been sent successfully!"
                isVisible={isSubmitted}
                onClose={() => setIsSubmitted(false)}
              />
            )}
          </AnimatePresence>

          {["name", "email", "message"].map((field, index) => (
            <motion.div
              key={field}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <label className="block text-sm font-medium text-gray-700 mb-1 capitalize">
                {field}
              </label>
              {field === "message" ? (
                <textarea
                  name={field}
                  value={formData[field]}
                  onChange={handleChange}
                  className="w-full border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-primary-blue focus:border-transparent transition duration-200"
                  placeholder={`Enter your ${field}`}
                  rows="4"
                  required
                />
              ) : (
                <input
                  type={field === "email" ? "email" : "text"}
                  name={field}
                  value={formData[field]}
                  onChange={handleChange}
                  className="w-full border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-primary-blue focus:border-transparent transition duration-200"
                  placeholder={`Enter your ${field}`}
                  required
                />
              )}
            </motion.div>
          ))}

          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            type="submit"
            className="w-full bg-primary-blue text-white font-semibold py-3 px-6 rounded-lg hover:bg-primary-dark transition duration-300 ease-in-out flex items-center justify-center space-x-2"
          >
            <Send className="w-5 h-5" />
            <span>Send Message</span>
          </motion.button>
        </form>
      </div>

      {/* Embedded Google Map */}
      <div className="mt-12">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11618.775111633016!2d54.498197104380104!3d24.378016705163606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e697101d78df7%3A0xd9ab96e3c68cfb26!2z2YHYrdi1INiz2YrYp9ix2KfYqiDYqNix2YjZgdmK2LPZiNix!5e1!3m2!1sen!2sae!4v1724527387051!5m2!1sen!2sae"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="rounded-lg shadow-lg"
        ></iframe>
      </div>
    </motion.div>
  );
};

export default Contact;
