import React from "react";
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { MdOutlineVerifiedUser } from "react-icons/md";
import { motion } from "framer-motion";
import PostRepairImage from "../../assets/post-repair.png";

const PostRepairVerification = () => {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir() === 'rtl';

    return (
        <div className={`container mx-auto p-6 pt-24 ${isRTL ? 'rtl' : ''}`}>
            <Helmet>
                <title>{t('postRepairPage.metaTitle')}</title>
                <meta name="description" content={t('postRepairPage.metaDescription')} />
                <meta name="keywords" content="Post repair verification, Vehicle inspection, Dubai, Abu Dhabi, UAE, Car safety, Auto repair, Technical inspection" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>

            <div className="flex flex-col lg:flex-row gap-8 items-center">
                <div className="w-full lg:w-1/2">
                    <motion.div
                        initial={{ opacity: 0, x: isRTL ? 100 : -100 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                        className="bg-white shadow-lg rounded-lg p-6"
                    >
                        <div className="flex items-center mb-4">
                            <MdOutlineVerifiedUser className="text-blue-500 w-16 h-16 mr-4" />
                            <h1 className="text-3xl font-bold text-gray-800">
                                {t('postRepairPage.title')}
                            </h1>
                        </div>
                        <p className="text-lg text-gray-600 mt-4">
                            {t('postRepairPage.description', { companyName: 'Al Professor Technical Inspection', location: 'Abu Dhabi' })}
                        </p>
                    </motion.div>
                </div>
                <div className="w-full lg:w-1/2">
                    <motion.div
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        <img
                            src={PostRepairImage}
                            alt={t('postRepairPage.imageAlt')}
                            className="w-full h-auto max-w-md max-h-96 rounded-lg shadow-md"
                        />
                    </motion.div>
                </div>
            </div>

            <div className="mt-8 bg-gray-100 p-6 rounded-lg">
                <h2 className="text-2xl font-semibold text-gray-800">{t('postRepairPage.verifyTitle')}</h2>
                <ul className="list-disc list-inside text-lg text-gray-700 mt-4 space-y-2">
                    {t('postRepairPage.verifyPoints', { returnObjects: true }).map((point, index) => (
                        <li key={index}>{point}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default PostRepairVerification;
