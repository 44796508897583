import React, { useState } from 'react';
import { db, storage, auth, addDoc, collection } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useAuthState } from 'react-firebase-hooks/auth';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddPost = () => {
    const [user] = useAuthState(auth); // Assuming auth is exported from firebase.js
    const [title, setTitle] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [description, setDescription] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [language, setLanguage] = useState('english');
    const [coverImage, setCoverImage] = useState(null);
    const [contentImage1, setContentImage1] = useState(null);
    const [contentImage2, setContentImage2] = useState(null);
    const [tags, setTags] = useState('');
    const [content, setContent] = useState('');

    const handleFileUpload = (file, setImageUrl) => {
        const storageRef = ref(storage, `images/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed', 
            (snapshot) => {
                // Handle progress
            }, 
            (error) => {
                console.error('Upload failed:', error);
            }, 
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setImageUrl(downloadURL);
                });
            }
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!user) {
            alert('You must be logged in to add a post.');
            return;
        }

        // Upload images and get URLs
        let coverImageUrl = '', contentImage1Url = '', contentImage2Url = '';
        if (coverImage) await handleFileUpload(coverImage, (url) => coverImageUrl = url);
        if (contentImage1) await handleFileUpload(contentImage1, (url) => contentImage1Url = url);
        if (contentImage2) await handleFileUpload(contentImage2, (url) => contentImage2Url = url);

        const post = {
            title,
            metaTitle,
            description,
            metaDescription,
            language,
            coverImage: coverImageUrl,
            contentImage1: contentImage1Url,
            contentImage2: contentImage2Url,
            tags: tags.split(',').map(tag => tag.trim()),
            author: user.displayName || user.email,
            postDate: new Date(),
            content,
        };

        try {
            await addDoc(collection(db, 'posts'), post);
            alert('Post added successfully!');
            // Reset form fields
            setTitle('');
            setMetaTitle('');
            setDescription('');
            setMetaDescription('');
            setLanguage('english');
            setCoverImage(null);
            setContentImage1(null);
            setContentImage2(null);
            setTags('');
            setContent('');
        } catch (error) {
            console.error('Error adding post:', error);
            alert('Failed to add post.');
        }
    };

    return (
        <div className='my-10 pt-20'> 
        <form onSubmit={handleSubmit} className="p-10 bg-white shadow-md rounded-md max-w-3xl mx-auto">
            <h1 className="text-2xl font-bold mb-4">Add New Post</h1>

            <div className="mb-4">
                <label className="block text-gray-700 mb-2">Title</label>
                <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                    required
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 mb-2">Meta Title</label>
                <input
                    type="text"
                    value={metaTitle}
                    onChange={(e) => setMetaTitle(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 mb-2">Description</label>
                <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                    required
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 mb-2">Meta Description</label>
                <textarea
                    value={metaDescription}
                    onChange={(e) => setMetaDescription(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 mb-2">Language</label>
                <select
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                >
                    <option value="english">English</option>
                    <option value="arabic">Arabic</option>
                </select>
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 mb-2">Cover Image</label>
                <input
                    type="file"
                    onChange={(e) => setCoverImage(e.target.files[0])}
                    className="w-full p-2 border border-gray-300 rounded-md"
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 mb-2">Content Image 1</label>
                <input
                    type="file"
                    onChange={(e) => setContentImage1(e.target.files[0])}
                    className="w-full p-2 border border-gray-300 rounded-md"
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 mb-2">Content Image 2</label>
                <input
                    type="file"
                    onChange={(e) => setContentImage2(e.target.files[0])}
                    className="w-full p-2 border border-gray-300 rounded-md"
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 mb-2">Tags (comma-separated)</label>
                <input
                    type="text"
                    value={tags}
                    onChange={(e) => setTags(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 mb-2">Content</label>
                <ReactQuill
                    value={content}
                    onChange={setContent}
                    className="bg-white"
                />
            </div>

            <button type="submit" className="bg-blue-500 text-white p-3 rounded-md w-full">
                Add Post
            </button>
        </form>
        </div>
    );
};

export default AddPost;
