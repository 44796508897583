import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from '../CustomButton';
import { FaHome, FaCar, FaBook, FaCog, FaSignOutAlt, FaBars } from 'react-icons/fa';
import { TfiWrite } from "react-icons/tfi";

const Sidebar = ({ isOpen, toggleSidebar }) => {
    return (
        <div className={`fixed z-20 inset-0 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out lg:relative lg:translate-x-0 w-64 bg-gray-800 text-white p-6 lg:h-screen my-15`}>
            <div className="flex justify-between items-center lg:hidden mb-8">
                <h1 className="text-2xl font-bold">Menu</h1>
                <button onClick={toggleSidebar} className="text-white">
                    <FaBars size={24} />
                </button>
            </div>
            <nav className="flex flex-col gap-4">
                <Link to="/" className="hover:bg-gray-700 p-2 rounded flex items-center gap-2">
                    <FaHome size={20} />
                    <CustomButton 
                        title="Home" 
                        buttonStyle="w-full text-left text-white" 
                    />
                </Link>
                <Link to="/dashboard/inspections" className="hover:bg-gray-700 p-2 rounded flex items-center gap-2">
                    <FaCar size={20} />
                    <CustomButton 
                        title="Inspections" 
                        buttonStyle="w-full text-left text-white" 
                    />
                </Link>
                <Link to="/dashboard/book" className="hover:bg-gray-700 p-2 rounded flex items-center gap-2">
                    <FaBook size={20} />
                    <CustomButton 
                        title="Book Inspection" 
                        buttonStyle="w-full text-left text-white" 
                    />
                </Link>
                <Link to="/blog" className="hover:bg-gray-700 p-2 rounded flex items-center gap-2">
                    <TfiWrite size={20} />
                    <CustomButton 
                        title="Blog" 
                        buttonStyle="w-full text-left text-white" 
                    />
                </Link>
                <Link to="/dashboard/settings" className="hover:bg-gray-700 p-2 rounded flex items-center gap-2">
                    <FaCog size={20} />
                    <CustomButton 
                        title="Settings" 
                        buttonStyle="w-full text-left text-white" 
                    />
                </Link>
            </nav>
            <div className="mt-auto">
                <CustomButton 
                    title="Log Out" 
                    buttonStyle="w-full bg-red-600 text-white rounded-full mt-4 flex items-center gap-2" 
                >
                    <FaSignOutAlt size={20} />
                </CustomButton>
            </div>
        </div>
    );
}

export default Sidebar;
