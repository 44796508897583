import React from "react";
import { MdChecklist } from "react-icons/md";
import { Helmet } from 'react-helmet';
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';

const PreInspect = () => {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir() === 'rtl';

    return (
        <div className={`container mx-auto p-6 pt-24 ${isRTL ? 'rtl' : ''}`}>
            <Helmet>
                <title>{t('preInspectPage.metaTitle')}</title>
                <meta name="description" content={t('preInspectPage.metaDescription')} />
                <meta name="keywords" content="Pre-purchase car inspection, Vehicle inspection, Abu Dhabi, Dubai, Technical inspection, Car maintenance, UAE" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>

            <div className="flex flex-col lg:flex-row gap-8 items-center">
                <div className="w-full lg:w-1/2">
                    <motion.div
                        initial={{ opacity: 0, x: isRTL ? 100 : -100 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                        className="bg-white shadow-lg rounded-lg p-6"
                    >
                        <div className="flex items-center mb-4">
                            <MdChecklist className="text-blue-500 w-16 h-16 mr-4" />
                            <h1 className="text-3xl font-bold text-gray-800">
                                {t('preInspectPage.title')}
                            </h1>
                        </div>
                        <p className="text-lg text-gray-600 mt-4">
                            {t('preInspectPage.description', { companyName: 'Al Professor Technical Inspection', location: 'Abu Dhabi' })}
                        </p>
                    </motion.div>
                </div>
                <div className="w-full lg:w-1/2">
                    <motion.div
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        <img
                            src="https://mycarrepairdubai.com/wp-content/uploads/2022/08/Car-Inspection.png"
                            alt={t('preInspectPage.imageAlt')}
                            className="w-full h-auto max-w-md max-h-96 rounded-lg shadow-md"
                        />
                    </motion.div>
                </div>
            </div>

            <div className="mt-8 bg-gray-100 p-6 rounded-lg">
                <h2 className="text-2xl font-semibold text-gray-800">{t('preInspectPage.coverTitle')}</h2>
                <ul className="list-disc list-inside text-lg text-gray-700 mt-4 space-y-2">
                    {t('preInspectPage.coverPoints', { returnObjects: true }).map((point, index) => (
                        <li key={index}>{point}</li>
                    ))}
                </ul>
            </div>

            <div className="mt-8 bg-white p-6 shadow-lg rounded-lg">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">{t('preInspectPage.additionalTitle')}</h2>
                <ul className="list-disc list-inside text-lg text-gray-700 space-y-2">
                    {t('preInspectPage.additionalPoints', { returnObjects: true }).map((point, index) => (
                        <li key={index}>{point}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default PreInspect;
