import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaTiktok, FaInstagram } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const footerLinks = [
    {
        title: 'company',
        links: [
            { title: 'aboutUs', url: '/#about' },
            { title: 'stats', url: '/' },
            { title: 'careers', url: '/' },
        ],
    },
    {
        title: 'support',
        links: [
            { title: 'helpCenter', url: '/' },
            { title: 'contactUs', url: '/contact' },
            { title: 'communityGuidelines', url: '/' },
        ],
    },
    {
        title: 'legal',
        links: [
            { title: 'cookiesPolicy', url: '/cookies-policy' },
            { title: 'privacyPolicy', url: '/privacy-policy' },
            { title: 'termsOfService', url: '/' },
        ],
    },
];

const Footer = () => {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();

    const isRTL = i18n.language === 'ar';

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <footer className={`flex flex-col border-t border-gray-100 text-black-100 mt-5 ${isRTL ? 'text-right' : 'text-left'}`} dir={isRTL ? 'rtl' : 'ltr'}>
            <div className="flex justify-between max-md:flex-col flex-wrap gap-5 sm:px-16 px-6 py-10">
                <div className="flex flex-col justify-start items-start gap-5">
                    <img src="https://hosting.renderforestsites.com/22517494/1084443/media/79c4eb41550828633925798e08c9633a.png" alt={t('logo')} width={118} height={18} className="object-contain" />
                    <p className="text-base text-gray-700">
                        {t('companyName')} <br />
                        {t('companyAddress')}
                    </p>
                    <div className={`mt-4 flex ${isRTL ? 'space-x-reverse' : 'space-x-4'}`}>
                        <a href="https://www.tiktok.com/@professorcarcenter" target="_blank" rel="noopener noreferrer" className="text-gray-700 hover:text-black">
                            <FaTiktok size={24} />
                        </a>
                        <a href="https://www.instagram.com/cars.inspection?igsh=aXBtdmxzdHY1eTAz" target="_blank" rel="noopener noreferrer" className="text-gray-700 hover:text-black">
                            <FaInstagram size={24} />
                        </a>
                    </div>
                </div>
                <div className="flex-1 w-full flex md:justify-end flex-wrap max-md:mt-10 gap-20">
                    {footerLinks.map((link) => (
                        <div key={link.title} className="flex flex-col gap-6 text-base min-w-[170px]">
                            <h3 className="font-bold">{t(link.title)}</h3>
                            <div className="flex flex-col gap-5">
                                {link.links.map((item) => (
                                    <Link to={item.url} className="text-gray-500" key={item.title}>
                                        {t(item.title)}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex justify-between items-center flex-wrap mt-10 border-t border-gray-100 sm:px-16 px-6 py-10">
                <p>&copy; {t('companyName')}. {t('allRightsReserved')}</p>
                <div className="flex flex-1 sm:justify-end justify-center max-sm:mt-4 gap-10">
                    <Link to="/privacy-policy" className="text-gray-500">{t('privacyPolicy')}</Link>
                    <Link to="/" className="text-gray-500">{t('termsAndConditions')}</Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
