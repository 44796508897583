import React from "react";
import { X } from "lucide-react";
import { CheckCircle } from "lucide-react";

const TypeDetails = ({ isOpen, closeModule, inspection }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
            <div className="bg-white rounded-lg p-4 w-full max-w-[18rem] sm:max-w-[20rem] md:max-w-md lg:max-w-lg xl:max-w-2xl relative">
                <button
                    onClick={closeModule}
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                    aria-label="Close"
                >
                    <X size={24} />
                </button>
                <div className="mb-4">
                    <h2 className="text-lg sm:text-xl font-semibold mb-4 pr-8">{inspection.title}</h2>
                    <ul className="space-y-3">
                        {inspection.details.map((detail, idx) => (
                            <li key={idx} className="flex items-start space-x-3">
                                <CheckCircle size={20} className="text-blue-500 flex-shrink-0 mt-1" />
                                <span className="text-sm sm:text-base">{detail}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default TypeDetails;