import React from "react";
import { GiSteeringWheel } from "react-icons/gi";
import { motion } from "framer-motion";
import RoadSimuImg from "../../assets/road-simu.png";
import { useTranslation } from "react-i18next";

const RoadSimulationTesting = () => {
    const { t } = useTranslation();

    return (
        <div className="container mx-auto p-6 pt-24">
            <div className="flex flex-col lg:flex-row gap-8 items-center">
                <div className="w-full lg:w-1/2">
                    <motion.div
                        initial={{ opacity: 0, x: -100 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                        className="bg-white shadow-lg rounded-lg p-6"
                    >
                        <div className="flex items-center mb-4">
                            <GiSteeringWheel className="text-blue-500 w-16 h-16 mr-4" />
                            <h1 className="text-3xl font-bold text-gray-800">
                                {t('roadSimulationTesting')}
                            </h1>
                        </div>
                        <p className="text-lg text-gray-600 mt-4" dangerouslySetInnerHTML={{ __html: t('roadSimulationTestingDescription') }} />
                    </motion.div>
                </div>
                <div className="w-full lg:w-1/2">
                    <motion.div
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        <img
                            src={RoadSimuImg}
                            alt={t('roadSimulationTesting')}
                            className="w-full h-auto max-w-md max-h-55 rounded-lg shadow-md"
                        />
                    </motion.div>
                </div>
            </div>

            <div className="mt-8 bg-gray-100 p-6 rounded-lg">
                <h2 className="text-2xl font-semibold text-gray-800">{t('whatWeTest')}</h2>
                <ul className="list-disc list-inside text-lg text-gray-700 mt-4 space-y-2">
                    <li>{t('steeringResponsiveness')}</li>
                    <li>{t('suspensionPerformance')}</li>
                    <li>{t('wheelAlignment')}</li>
                    <li>{t('shockAbsorberFunction')}</li>
                    <li>{t('overallDrivingComfort')}</li>
                </ul>
            </div>
        </div>
    );
}

export default RoadSimulationTesting;
