import React from "react";
import { FaBriefcase } from "react-icons/fa";
import { motion } from "framer-motion";

const VehicleAppraisalsForBanks = () => {
    return (
        <div className="container mx-auto p-6 pt-24">
            <div className="flex flex-col lg:flex-row gap-8 items-center">
                <div className="w-full lg:w-1/2">
                    <motion.div
                        initial={{ opacity: 0, x: -100 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                        className="bg-white shadow-lg rounded-lg p-6"
                    >
                        <div className="flex items-center mb-4">
                            <FaBriefcase className="text-blue-500 w-16 h-16 mr-4" />
                            <h1 className="text-3xl font-bold text-gray-800">
                                Vehicle Appraisals for Banks
                            </h1>
                        </div>
                        <p className="text-lg text-gray-600 mt-4">
                            At <strong>Al Professor Technical Inspection</strong> in <strong>Abu Dhabi</strong>, we provide detailed vehicle appraisals specifically tailored for banks. Our appraisals are thorough, ensuring lenders have the accurate information they need to make informed decisions about financing.
                        </p>
                    </motion.div>
                </div>
                <div className="w-full lg:w-1/2">
                    <motion.div
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        <img
                            src="https://example.com/vehicle-appraisals.png"
                            alt="Vehicle Appraisals"
                            className="w-full h-auto max-w-md max-h-96 rounded-lg shadow-md"
                        />
                    </motion.div>
                </div>
            </div>

            <div className="mt-8 bg-gray-100 p-6 rounded-lg">
                <h2 className="text-2xl font-semibold text-gray-800">What Our Appraisals Include:</h2>
                <ul className="list-disc list-inside text-lg text-gray-700 mt-4 space-y-2">
                    <li>Comprehensive vehicle assessment</li>
                    <li>Market value analysis</li>
                    <li>Condition and history reports</li>
                    <li>Loan-to-value ratio (LTV) calculations</li>
                </ul>
            </div>
        </div>
    );
};

export default VehicleAppraisalsForBanks;
