import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { FaRegClock, FaUser } from 'react-icons/fa';

const BlogPost = () => {
    const { id } = useParams();
    const [post, setPost] = useState(null);

    useEffect(() => {
        const fetchPost = async () => {
            const docRef = doc(db, 'posts', id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setPost(docSnap.data());
            } else {
                console.log('No such document!');
            }
        };

        fetchPost();
    }, [id]);

    if (!post) {
        return <div className="p-6 text-center">Loading...</div>;
    }

    return (
        <div className="pt-20">
            <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md">
                <h1 className="text-4xl font-bold text-gray-800 mb-4">{post.title}</h1>
                <div className="flex items-center justify-between text-gray-600 mb-6">
                    <div className="flex items-center">
                        <FaUser className="mr-2" />
                        <span>{post.author}</span>
                    </div>
                    <div className="flex items-center">
                        <FaRegClock className="mr-2" />
                        <span>{new Date(post.postDate.toDate()).toLocaleDateString()}</span>
                    </div>
                </div>

                {post.coverImage && (
                    <div className="mb-6">
                        <img src={post.coverImage} alt={post.title} className="w-full h-auto rounded-md" />
                    </div>
                )}

                <p className="text-gray-700 text-lg mb-6">{post.description}</p>

                {post.contentImage1 && (
                    <div className="mb-6">
                        <img src={post.contentImage1} alt="Content Image 1" className="w-full h-auto rounded-md" />
                    </div>
                )}

                <div className="prose max-w-full mb-6" dangerouslySetInnerHTML={{ __html: post.content }}></div>

                {post.contentImage2 && (
                    <div className="mb-6">
                        <img src={post.contentImage2} alt="Content Image 2" className="w-full h-auto rounded-md" />
                    </div>
                )}

                <div className="text-gray-500 text-sm mt-6">
                    <p><strong>Tags:</strong> {Array.isArray(post.tags) ? post.tags.join(', ') : post.tags}</p>
                </div>
            </div>
        </div>
    );
};

export default BlogPost;
