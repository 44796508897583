import React, { useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Footer from './components/Footer';
import TypesOfInspection from './components/TypesOfInspection';
import Services from './components/Services';
import Book from './components/Book'; 
import Contact from './components/Contact';
import About from './components/About';
import Reviews from './components/Reviews';
import CookiesPolicy from './components/CookiesPolicy';
import PrivacyPolicy from './components/PrivacyPolicy';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import { AuthProvider } from './context/AuthContext';
import Dashboard from './components/dashboard/Dashboard';
import AddPost from './components/blog/AddPost';
import BlogList from './components/blog/BlogList';
import BlogPost from './components/blog/BlogPost';
import EditPost from './components/blog/EditPost';
import PreInspect from './components/services/PreInspection';
import RepairInspection from './components/services/RepairInspection';
import PostRepairVerification from './components/services/PostRepairVerification';
import RoadSimulationTesting from './components/services/RoadSimulationTesting';
import VehicleAppraisalsForBanks from './components/services/VehicleAppraisalsForBanks';
import CarInspectionSeoPage from './components/seo/CarInspect';
import TradingTools from './components/seo/TradingTools'
import WhatsAppIcon from './components/WhatsAppFloating';

function App() {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      // Track page view for both Google Analytics and Google Ads
      window.gtag('config', 'AW-16721393829', {
        page_path: location.pathname,
      });
      window.gtag('config', 'G-776NRXFJYE', {
        page_path: location.pathname,
      });
    }
  }, [location]);

  return (
    <AuthProvider>
      <div className="relative overflow-x-hidden">
        {location.pathname !== '/dashboard' && <Header />}
        {/* Add padding-top to account for fixed header height */}
        <main className="pt-20 sm:pt-24">
          <Routes>
            {/* Redirect to default language (Arabic) if no language is in the URL */}
            <Route path="/" element={<Navigate to="/ar/" />} />
            
            {/* Define routes with :lang parameter for language support */}
            <Route path="/:lang/" element={
              <>
                <Hero />
                <Services />
                <TypesOfInspection />
                <About />
                <Reviews />
              </>
            } />
            <Route path="/:lang/book" element={<Book />} />
            <Route path="/:lang/contact" element={<Contact />} />
            <Route path="/:lang/cookies-policy" element={<CookiesPolicy />} />
            <Route path="/:lang/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/:lang/login" element={<Login />} />
            <Route path="/:lang/register" element={<Register />} />
            <Route path="/:lang/dashboard" element={<Dashboard />} />
            <Route path="/:lang/add-post" element={<AddPost />} />
            <Route path="/:lang/blog" element={<BlogList />} />
            <Route path="/:lang/blog/:id" element={<BlogPost />} />
            <Route path="/:lang/edit-post/:id" element={<EditPost />} />
            
            {/* Services Routes */}
            <Route path="/:lang/services/pre-purchase-inspections" element={<PreInspect />} />
            <Route path="/:lang/services/pre-repair-diagnostics" element={<RepairInspection />} />
            <Route path="/:lang/services/post-repair-verification" element={<PostRepairVerification />} />
            <Route path="/:lang/services/road-simulation-testing" element={<RoadSimulationTesting />} />
            <Route path="/:lang/services/vehicle-appraisals-for-banks" element={<VehicleAppraisalsForBanks />} />
            <Route path="/:lang/car-inspection-uae" element={<CarInspectionSeoPage />} />
            <Route path="/:lang/trading-tools" element={<TradingTools />} />
          </Routes>
        </main>
        <Footer />
        <WhatsAppIcon /> {/* Floating icon */}
      </div>
    </AuthProvider>
  );
}

export default App;
