import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdLogIn } from "react-icons/io";
import { HiMenu, HiX } from "react-icons/hi";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import profLogo from '../assets/prof-logo.png';

const Header = () => {
  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isRTL, setIsRTL] = useState(i18n.language === 'ar');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const langFromUrl = location.pathname.split('/')[1];
    if (langFromUrl === 'ar' || langFromUrl === 'en') {
      i18n.changeLanguage(langFromUrl);
    } else {
      navigate(`/ar${location.pathname}`);
    }
    setIsRTL(i18n.language === 'ar');
    document.body.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
  }, [i18n.language, location, navigate]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleServicesClick = () => {
    navigate(`/${i18n.language}/`);
    setTimeout(() => {
      const section = document.getElementById('services-section');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  };

  const switchLanguage = (lang) => {
    i18n.changeLanguage(lang);
    const newUrl = `/${lang}${location.pathname.substring(3)}`; // Update URL for language change
    navigate(newUrl);
  };

  return (
    <header className={isRTL ? 'rtl' : 'ltr'}>
      <div className="w-full z-50 fixed top-0 left-0 bg-white shadow-md">
        <nav className="flex justify-between items-center container mx-auto sm:px-16 px-6 py-4">
          <Link to={`/${i18n.language}/`} className="flex justify-center items-center">
            <img src={profLogo} alt="Al-Professor Logo" className="h-10 w-auto max-w-full" />
          </Link>

          <div className={`hidden md:flex ${isRTL ? 'space-x-reverse space-x-8' : 'space-x-8'}`}>
            <Link to={`/${i18n.language}/`} className="text-lg font-medium text-gray-700 hover:text-black transition-colors">
              {t('home')}
            </Link>
            <span onClick={handleServicesClick} className="text-lg font-medium text-gray-700 hover:text-black transition-colors cursor-pointer">
              {t('services')}
            </span>
            <Link to={`/${i18n.language}/book`} className="text-lg font-medium text-gray-700 hover:text-black transition-colors">
              {t('book')}
            </Link>
            <Link to={`/${i18n.language}/blog`} className="text-lg font-medium text-gray-700 hover:text-black transition-colors">
              {t('blog')}
            </Link>
            <a href="https://www.google.com/search?sca_esv=e7a2bf937769b224&hl=en-AE&gl=ae&sxsrf=ADLYWILKY0KhxGgcPYb5iPOCjFEh5c9RXg:1724579553317&q=%D9%81%D8%AD%D8%B5+%D8%B3%D9%8A%D8%A7%D8%B1%D8%A7%D8%AA+%D8%A8%D8%B1%D9%88%D9%81%D9%8A%D8%B3%D9%88%D8%B1+reviews" className="text-lg font-medium text-gray-700 hover:text-black transition-colors">
              {t('review_us')}
            </a>
            <a href="tel:00971561868236" className="text-lg font-medium text-gray-700 hover:text-black transition-colors">
              {t('contact')}
            </a>
          </div>

          <div className={`md:hidden flex items-center ${isRTL ? 'mr-auto' : 'ml-auto'}`}>
            <button onClick={toggleMenu} className="text-2xl text-black focus:outline-none">
              {menuOpen ? <HiX /> : <HiMenu />}
            </button>
          </div>

          <div className={`hidden md:flex logedin ${isRTL ? 'space-x-reverse space-x-4' : 'space-x-4'}`}>
            <button onClick={() => switchLanguage('en')} className="px-3 py-1 bg-white rounded shadow-sm hover:shadow-md transition-shadow">EN</button>
            <button onClick={() => switchLanguage('ar')} className="px-3 py-1 bg-white rounded shadow-sm hover:shadow-md transition-shadow">AR</button>
            <a href="/login" className="text-gray-700 hover:text-black transition-colors">
              <IoMdLogIn size={24} />
            </a>
          </div>
        </nav>

        {/* Mobile Menu */}
        {menuOpen && (
          <div className={`md:hidden absolute top-full left-0 w-full flex flex-col ${isRTL ? 'items-end text-right' : 'items-start text-left'} space-y-2 p-4 bg-gray-50 rounded-b-lg shadow-lg`}>
            <Link to={`/${i18n.language}/`} className="text-lg font-medium text-gray-700 hover:text-black transition-colors">
              {t('home')}
            </Link>
            <span onClick={handleServicesClick} className="text-lg font-medium text-gray-700 hover:text-black transition-colors cursor-pointer">
              {t('services')}
            </span>
            <Link to={`/${i18n.language}/book`} className="text-lg font-medium text-gray-700 hover:text-black transition-colors">
              {t('book')}
            </Link>
            <Link to={`/${i18n.language}/blog`} className="text-lg font-medium text-gray-700 hover:text-black transition-colors">
              {t('blog')}
            </Link>
            <a href="https://www.google.com/search?sca_esv=e7a2bf937769b224&hl=en-AE&gl=ae&sxsrf=ADLYWILKY0KhxGgcPYb5iPOCjFEh5c9RXg:1724579553317&q=%D9%81%D8%AD%D8%B5+%D8%B3%D9%8A%D8%A7%D8%B1%D8%A7%D8%AA+%D8%A8%D8%B1%D9%88%D9%81%D9%8A%D8%B3%D9%88%D8%B1+reviews" className="text-lg font-medium text-gray-700 hover:text-black transition-colors">
              {t('review_us')}
            </a>
            <a href="tel:00971561868236" className="text-lg font-medium text-gray-700 hover:text-black transition-colors">
              {t('contact')}
            </a>

            {/* Language Switcher in Mobile */}
            <div className={`flex ${isRTL ? 'space-x-reverse space-x-2' : 'space-x-2'} w-full ${isRTL ? 'justify-end' : 'justify-start'} mt-4`}>
              <button onClick={() => switchLanguage('en')} className="px-3 py-1 bg-white rounded shadow-sm hover:shadow-md transition-shadow">EN</button>
              <button onClick={() => switchLanguage('ar')} className="px-3 py-1 bg-white rounded shadow-sm hover:shadow-md transition-shadow">AR</button>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
