import React, { useState } from 'react';
import Sidebar from './Sidebar';
import { FaBars } from 'react-icons/fa';

const Dashboard = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="flex h-screen mt-15">
            {/* Sidebar */}
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

            {/* Main Content Area */}
            <div className="flex-1 bg-gray-100 p-6 lg:ml-64 mt-15">
                <div className="lg:hidden flex justify-between items-center mb-4">
                    <button onClick={toggleSidebar} className="text-gray-800">
                        <FaBars size={24} />
                    </button>
                </div>
                <h1 className="text-2xl font-bold mb-4">Dashboard Content</h1>
                <p className="text-lg text-gray-700">
                    This is where your main dashboard content will go. 
                </p>
            </div>
        </div>
    );
}

export default Dashboard;
