import React from "react";
import { motion } from "framer-motion";
import { FaCar, FaShieldAlt, FaTools, FaUniversity, FaSearch } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const serviceItems = [
  { icon: FaSearch, text: "Pre-purchase car inspections", i18nKey: "prePurchase" },
  { icon: FaCar, text: "Car valuation and pricing assessments", i18nKey: "carValuation" },
  { icon: FaTools, text: "Pre-repair diagnostics and fault identification", i18nKey: "preRepair" },
  { icon: FaShieldAlt, text: "Post-repair car inspections", i18nKey: "postRepair" },
  { icon: FaUniversity, text: "Car inspections and evaluations for banks and finance companies", i18nKey: "carInspectionsBanks" },
];

const About = () => {
  const { t, i18n } = useTranslation();

  const isRTL = i18n.language === 'ar';

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className={`container mx-auto p-8 max-w-4xl bg-white rounded-lg shadow-lg ${isRTL ? 'text-right' : 'text-left'}`}
      dir={isRTL ? 'rtl' : 'ltr'}
    >
      <motion.h2
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
        className="text-3xl md:text-4xl font-bold text-center mt-6 mb-8 text-black"
      >
        {t('aboutTitle')}
      </motion.h2>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        <p className="mt-4 text-base md:text-lg text-gray-700 leading-relaxed">
          {t('aboutParagraph1')}
        </p>
        <p className="mt-4 text-base md:text-lg text-gray-700 leading-relaxed">
          {t('aboutParagraph2')}
        </p>
      </motion.div>

      <motion.h3
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.4, duration: 0.5 }}
        className="text-xl md:text-2xl font-semibold mt-8 mb-4 text-black"
      >
        {t('weSpecializeIn')}
      </motion.h3>

      <motion.ul className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        {serviceItems.map((item, index) => (
          <motion.li
            key={index}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.5 + index * 0.1, duration: 0.5 }}
            className="flex items-center space-x-3 bg-gray-100 p-4 rounded-lg shadow-md"
          >
            <item.icon className="w-6 h-6 text-primary-blue" />
            <span className="text-black">{t(item.i18nKey)}</span>
          </motion.li>
        ))}
      </motion.ul>

      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1, duration: 0.5 }}
        className="mt-8 text-base md:text-lg text-gray-700 leading-relaxed"
      >
        {t('finalParagraph')}
      </motion.p>

      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ delay: 1.2, duration: 0.5 }}
        className="mt-8 text-center"
      >
        <Link to="/contact" className="inline-block bg-primary-blue text-white px-6 py-3 rounded-lg shadow-md hover:bg-primary-dark">
          {t('contactUs')}
        </Link>
      </motion.div>
    </motion.div>
  );
};

export default About;
