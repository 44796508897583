import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../config/firebase';
import { useParams, useNavigate } from 'react-router-dom';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { Editor } from '@tinymce/tinymce-react';
import ReactQuill from 'react-quill';
import CustomButton from '../CustomButton';

const EditPost = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [post, setPost] = useState(null);
    const [formData, setFormData] = useState({
        title: '',
        metaTitle: '',
        description: '',
        metaDescription: '',
        language: 'english',
        coverImage: '',
        contentImage1: '',
        contentImage2: '',
        tags: '',
        author: '',
        postDate: new Date(),
    });

    useEffect(() => {
        const fetchPost = async () => {
            const docRef = doc(db, 'posts', id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setPost(docSnap.data());
                setFormData({ ...docSnap.data(), postDate: docSnap.data().postDate.toDate() });
            } else {
                console.log('No such document!');
            }
        };

        fetchPost();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleEditorChange = (content, editor) => {
        setFormData((prevState) => ({
            ...prevState,
            description: content,
        }));
    };

    const handleImageUpload = async (file, field) => {
        if (file) {
            const storageRef = ref(storage, `blog-images/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                'state_changed',
                (snapshot) => { },
                (error) => {
                    console.error('Image upload error:', error);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        setFormData((prevState) => ({
                            ...prevState,
                            [field]: downloadURL,
                        }));
                    });
                }
            );
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateDoc(doc(db, 'posts', id), formData);
            navigate('/blog');
        } catch (error) {
            console.error('Error updating document:', error);
        }
    };

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white rounded shadow-md">
            <h1 className="text-2xl font-bold mb-6">Edit Blog Post</h1>
            <form onSubmit={handleFormSubmit}>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Title</label>
                    <input
                        type="text"
                        name="title"
                        value={formData.title}
                        onChange={handleInputChange}
                        className="mt-1 block w-full p-2 border rounded"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Meta Title</label>
                    <input
                        type="text"
                        name="metaTitle"
                        value={formData.metaTitle}
                        onChange={handleInputChange}
                        className="mt-1 block w-full p-2 border rounded"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Description</label>
                    <ReactQuill
                        value={formData.description}
                        onEditorChange={handleEditorChange}
                        init={{
                            height: 300,
                            menubar: false,
                            plugins: ['link image', 'code'],
                            toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code',
                        }}
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Meta Description</label>
                    <textarea
                        name="metaDescription"
                        value={formData.metaDescription}
                        onChange={handleInputChange}
                        className="mt-1 block w-full p-2 border rounded"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Language</label>
                    <select
                        name="language"
                        value={formData.language}
                        onChange={handleInputChange}
                        className="mt-1 block w-full p-2 border rounded"
                        required
                    >
                        <option value="english">English</option>
                        <option value="arabic">Arabic</option>
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Cover Image</label>
                    <input
                        type="file"
                        onChange={(e) => handleImageUpload(e.target.files[0], 'coverImage')}
                        className="mt-1 block w-full p-2 border rounded"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Content Image 1</label>
                    <input
                        type="file"
                        onChange={(e) => handleImageUpload(e.target.files[0], 'contentImage1')}
                        className="mt-1 block w-full p-2 border rounded"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Content Image 2</label>
                    <input
                        type="file"
                        onChange={(e) => handleImageUpload(e.target.files[0], 'contentImage2')}
                        className="mt-1 block w-full p-2 border rounded"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Tags</label>
                    <input
                        type="text"
                        name="tags"
                        value={formData.tags}
                        onChange={handleInputChange}
                        className="mt-1 block w-full p-2 border rounded"
                    />
                </div>
                <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Update Post
                </button>            </form>
        </div>
    );
}

export default EditPost;

