import React from "react";
import { useTranslation } from "react-i18next";
import { MdCarRepair, MdChecklist, MdOutlineVerifiedUser } from "react-icons/md";
import { GiSteeringWheel } from "react-icons/gi";
import { FaBriefcase } from "react-icons/fa";
import { TextGenerateEffect } from "../ui/TextGenerationEffect";
import { useNavigate } from "react-router-dom";

const ServiceCard = ({ title, description, icon: Icon, route }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(route);
    };

    return (
        <div
            className="bg-white shadow-md rounded-lg overflow-hidden flex flex-col p-4 transition-transform duration-300 hover:scale-105 cursor-pointer"
            onClick={handleClick}
        >
            <div className="flex items-center mb-4">
                <Icon className="w-10 h-10 text-blue-500 mr-2" />
                <h3 className="font-bold text-lg">{title}</h3>
            </div>
            <p className="text-base sm:text-lg text-gray-600">{description}</p>
        </div>
    );
};

const Services = () => {
    const { t } = useTranslation();

    const services = [
        {
            title: t("Pre-purchase Inspections"),
            description: t("Ensure you’re making a sound investment."),
            icon: MdChecklist,
            route: "/services/pre-purchase-inspections",
        },
        {
            title: t("Pre-repair Diagnostics"),
            description: t("Accurately identify and assess vehicle issues before repairs."),
            icon: MdCarRepair,
            route: "/services/pre-repair-diagnostics",
        },
        {
            title: t("Post-repair Verification"),
            description: t("Confirm that repairs have been carried out correctly and to a high standard."),
            icon: MdOutlineVerifiedUser,
            route: "/services/post-repair-verification",
        },
        {
            title: t("Vehicle Appraisals for Banks"),
            description: t("Provide detailed assessments for lending purposes."),
            icon: FaBriefcase,
            route: "/services/vehicle-appraisals-for-banks",
        },
        {
            title: t("Road Simulation Testing"),
            description: t("Employ specialized equipment to diagnose steering, suspension, and other moving mechanical components."),
            icon: GiSteeringWheel,
            route: "/services/road-simulation-testing",
        },
    ];

    return (
        <div className="container mx-auto p-4" id="services-section">
            <div className="text-center mb-8">
                <TextGenerateEffect 
                    words={t("Our Services")} 
                    className="text-2xl md:text-3xl font-semibold"
                />
                <p className="text-sm sm:text-base text-gray-600 mt-2">
                    {t("Discover the range of services we offer to ensure your vehicle is in top condition.")}
                </p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 justify-items-center">
                {services.map((service, idx) => (
                    <ServiceCard
                        key={idx}
                        title={service.title}
                        description={service.description}
                        icon={service.icon}
                        route={service.route}
                    />
                ))}
            </div>
        </div>
    );
};

export default Services;
