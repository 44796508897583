import React from "react";
import { motion } from "framer-motion";

const PrivacyPolicy = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto p-8 max-w-4xl bg-white rounded-lg shadow-lg"
    >
      <motion.h2
        initial={{ scale: 0.9 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
        className="text-3xl md:text-4xl font-bold text-center mt-6 mb-8 text-black"
      >
        Privacy Policy
      </motion.h2>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        <p className="mt-4 text-base md:text-lg text-gray-700 leading-relaxed">
          At Al Professor Center, your privacy is of utmost importance to us. This Privacy Policy outlines how we collect, use, and protect your personal information when you interact with our services.
        </p>
        <p className="mt-4 text-base md:text-lg text-gray-700 leading-relaxed">
          <strong>Information Collection:</strong> We may collect personal information such as your name, email address, phone number, and other relevant details when you voluntarily provide them through our contact forms or during service inquiries.
        </p>
        <p className="mt-4 text-base md:text-lg text-gray-700 leading-relaxed">
          <strong>Use of Information:</strong> The information we collect is used to provide you with the best possible service. This may include responding to your inquiries, processing your requests, and keeping you informed about our services and updates.
        </p>
        <p className="mt-4 text-base md:text-lg text-gray-700 leading-relaxed">
          <strong>Data Protection:</strong> We take the security of your personal information seriously. Our website is equipped with security measures to protect against unauthorized access, alteration, or disclosure of your personal data.
        </p>
        <p className="mt-4 text-base md:text-lg text-gray-700 leading-relaxed">
          <strong>Third-Party Sharing:</strong> We do not share your personal information with third parties unless required by law or with your explicit consent. We ensure that your data remains confidential and used solely for the purposes stated in this policy.
        </p>
        <p className="mt-4 text-base md:text-lg text-gray-700 leading-relaxed">
          We are committed to maintaining the trust and confidence of our clients. If you have any questions or concerns about our privacy practices, please do not hesitate to reach out to us.
        </p>
      </motion.div>

      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ delay: 1, duration: 0.5 }}
        className="mt-8 text-center"
      >
        <a
          href="/contact"
          className="inline-block bg-primary-blue text-white px-6 py-3 rounded-lg shadow-md hover:bg-primary-dark"
        >
          Contact Us for More Information
        </a>
      </motion.div>
    </motion.div>
  );
};

export default PrivacyPolicy;
