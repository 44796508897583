import React from 'react';
import { motion } from 'framer-motion';

const Alert = ({ type, message }) => {
  const alertStyles = {
    error: 'bg-red-100 text-red-700 border border-red-400',
    success: 'bg-green-100 text-green-700 border border-green-400',
    warning: 'bg-yellow-100 text-yellow-700 border border-yellow-400',
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
      className={`p-4 rounded-lg ${alertStyles[type]} mt-4`}
    >
      {message}
    </motion.div>
  );
};

export default Alert;
