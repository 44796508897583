import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { UserCheck, Cpu, ArrowUpZA } from "lucide-react";
import TypeDetails from "./TypeDetails";
import { TextGenerateEffect } from "../ui/TextGenerationEffect";
import { motion, useInView } from "framer-motion";

const Card = ({ children, className }) => (
  <div className={`bg-white shadow-md rounded-lg overflow-hidden flex flex-col ${className}`}>
    {children}
  </div>
);

const CardHeader = ({ children, className }) => (
  <div className={`px-4 py-3 sm:px-6 sm:py-4 flex items-center justify-between ${className}`}>
    {children}
  </div>
);

const CardContent = ({ children, className }) => (
  <div className={`px-4 py-3 sm:px-6 sm:py-4 flex-grow ${className}`}>
    {children}
  </div>
);

const Button = ({ children, onClick, className }) => (
  <button
    onClick={onClick}
    className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${className}`}
  >
    {children}
  </button>
);

const TypesOfInspection = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl';
  const [selectedInspection, setSelectedInspection] = useState(null);
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true });

  const inspectionTypes = [
    {
      id: 1,
      title: t('fully_comprehensive_inspection'),
      details: [
        t('details.comprehensive_1'),
        t('details.comprehensive_2'),
        t('details.comprehensive_3'),
        t('details.comprehensive_4'),
        t('details.comprehensive_5'),
        t('details.comprehensive_6')
      ],
      icon: ArrowUpZA 
    },
    {
      id: 2,
      title: t('third_party_inspection'),
      details: [
        t('details.third_party_1'),
        t('details.third_party_2'),
        t('details.third_party_3')
      ],
      icon: UserCheck
    },
    {
      id: 3,
      title: t('fault_diagnosis_check'),
      details: [
        t('details.fault_diagnosis_1'),
        t('details.fault_diagnosis_2'),
        t('details.fault_diagnosis_3')
      ],
      icon: Cpu
    }
  ];

  return (
    <div className={`container mx-auto p-4 ${isRTL ? 'rtl' : 'ltr'}`}>
      <div ref={ref} className="relative mb-8">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isInView ? 1 : 0 }}
          transition={{ duration: 1.5 }}
        >
          <TextGenerateEffect 
            words={t('types_of_inspection')} 
            className="text-center mb-8 text-2xl md:text-3xl" 
          />
        </motion.div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {inspectionTypes.map((inspection) => {
          const Icon = inspection.icon;
          return (
            <Card key={inspection.id} className="h-full">
              <CardHeader>
                <h3 className="font-bold text-lg text-center sm:text-left">{inspection.title}</h3>
                <Icon className="w-8 h-8 text-blue-500" />
              </CardHeader>
              <CardContent>
                <ul className="list-disc list-inside space-y-2 text-sm sm:text-base">
                  {inspection.details.map((detail, index) => (
                    <li key={index}>{detail}</li>
                  ))}
                </ul>
              </CardContent>
              <div className="px-4 py-3 sm:px-6 sm:py-4 mt-auto">
                <Button 
                  onClick={() => setSelectedInspection(inspection)}
                  className="w-full"
                >
                  {t('view_details')}
                </Button>
              </div>
            </Card>
          );
        })}
        {selectedInspection && (
          <TypeDetails 
            isOpen={!!selectedInspection} 
            closeModule={() => setSelectedInspection(null)} 
            inspection={selectedInspection} 
          />
        )}
      </div>
    </div>
  );
};

export default TypesOfInspection;
